import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

const Home = lazy(() => import('./home/home'));
const Search = lazy(() => import('./search/search'));
const Player = lazy(() => import('./player/player'));
const Refresh = lazy(() => import('./refresh/refresh'));

class App extends React.Component {

  componentDidMount() {
  }

  render() {
    return (
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/search" component={Search} />
            <Route path="/nwsrs_players/view" component={Player} />
            <Route path="/refresh" component={Refresh} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default App;
